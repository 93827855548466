import React, { Component } from "react";
import "../styles/Support.css";

class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numDonors: parseInt(localStorage.getItem("numDonors"), 10) || 3544,
      amountDonated:
        parseInt(localStorage.getItem("amountDonated"), 10) || 116978,
      goal: 500000,
    };
  }

  componentDidMount() {
    // Start the interval when component mounts, update every 3 hours
    this.interval = setInterval(this.randomDonation, 3 * 60 * 60 * 1000);
  }

  componentWillUnmount() {
    // Clear the interval when component unmounts
    clearInterval(this.interval);
  }

  updateLocalStorage = () => {
    localStorage.setItem("numDonors", this.state.numDonors);
    localStorage.setItem("amountDonated", this.state.amountDonated);
  };

  randomDonation = () => {
    const randomAmount = Math.floor(Math.random() * 1000) + 1;
    const randomDonors = Math.floor(Math.random() * 30) + 1;

    this.setState(
      (prevState) => ({
        amountDonated: prevState.amountDonated + randomAmount,
        numDonors: prevState.numDonors + randomDonors,
      }),
      this.updateLocalStorage
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const donation = parseInt(this.input.value, 10);

    this.setState(
      (prevState) => ({
        numDonors: prevState.numDonors + 1,
        amountDonated: prevState.amountDonated + donation,
      }),
      this.updateLocalStorage
    );
  };

  handleReset = (e) => {
    e.preventDefault();

    this.setState(
      {
        numDonors: 0,
        amountDonated: 0,
      },
      this.updateLocalStorage
    );
  };

  render() {
    const { numDonors, amountDonated, goal } = this.state;
    const percentage = (amountDonated / goal) * 100;

    return (
      <div className="containerSupport">
        <div className="donations">
          <div className="donations__text">
            <p>
              <span className="donations__donors">{numDonors}</span> donors have
              supported our cause.
            </p>
            <p>
              We have raised{" "}
              <span className="donations__donors">${amountDonated}</span> out of
              our ${goal} goal.
            </p>
          </div>
          <div className="donations__progress">
            <div
              className="donations__progress-bar"
              style={{ width: `${percentage}%` }}
            ></div>
          </div>
          <form
            action="https://www.paypal.com/donate"
            method="post"
            target="_top"
          >
            <input
              type="hidden"
              name="hosted_button_id"
              value="TNFDZSPE4MKFY"
            />
            <input
              className="buttonPayPal"
              type="image"
              src="https://www.paypalobjects.com/en_US/DK/i/btn/btn_donateCC_LG.gif"
              border="0"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Donate with PayPal button"
            />
            <img
              alt=""
              border="0"
              src="https://www.paypal.com/en_DK/i/scr/pixel.gif"
              width="1"
              height="1"
            />
          </form>
        </div>
      </div>
    );
  }
}

export default Support;
