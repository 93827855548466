import React, { useState } from "react";
import Logo from "../assets/israelLogo.png";
import { Link } from "react-router-dom";
import "../styles/Navbar.css";
import ReorderIcon from "@mui/icons-material/Reorder";

function Navbar() {
  const [openLinks, setOpenLinks] = useState(false);

  const toggleNavbar = () => {
    setOpenLinks(!openLinks);
  };

  return (
    <div className="navbar">
      <div className="leftSide" id={openLinks ? "open" : "close"}>
        <Link to="/home">
          <img src={Logo} alt="Your Logo" />
        </Link>
        {/* <div className="hiddenLinks">
          <Link to="/home">Home</Link>
          <Link to="/support">Support</Link>
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
        </div> */}
      </div>

      <div className="rightSide">
        <Link to="/home">Home</Link>
        <Link to="/support">Support</Link>
        {/* <Link to="/about">About</Link> */}
        <Link to="/contact">Contact</Link>
        {/* <button onClick={toggleNavbar}>
          <ReorderIcon />
        </button> */}
      </div>
    </div>
  );
}

export default Navbar;
