import React from "react";
import "../styles/Contact.css";
import israelWar from "../assets/yeehae.png";

function Contact() {
  return (
    <div className="contact">
      <div
        className="leftSide"
        style={{ backgroundImage: `url(${israelWar})` }}
      ></div>
      <div className="rightSideContact">
        <div className="content-wrapper">
          {" "}
          {/* Wrap the content in a div */}
          <h1> Contact Us</h1>
          <form id="contact-form" method="POST">
            <label htmlFor="name">Email</label>
            <h4>support@fundraiserisrael.com</h4>
            {/* <input name="name" placeholder="Enter full name..." type="text" /> */}
            {/* <label htmlFor="email">Phone</label>
            <h4>12 23 34 45 56</h4> */}
            {/* <input name "email" placeholder="Enter email..." type="email" */}
            <label htmlFor="message">Description</label>
            <p>
              Please do not hesitate to contact us with any inquiries or
              questions you may have concerning the fundraiser. You can reach us
              using the email provided above.
            </p>
            {/* <button > Send Message</button> */}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
