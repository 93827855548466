import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Support from "./pages/Support";
import About from "./pages/About";
import Contact from "./pages/Contact";
import GoDaddySeal from "./components/GoDaddySeal"; // Make sure this path is correct
import { HashRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/support" element={<Support />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        {/* Place the GoDaddySeal above the Footer so it's displayed at the bottom */}
        {/* <GoDaddySeal /> */}
        <Footer />
      </Router>
    </div>
  );
}

export default App;
