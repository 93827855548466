import React from "react";
import picture from "../assets/war.png";
import "../styles/Home.css";
import warAudio from "../assets/IsraelWar.mp3";
import Support from "./Support";
import { Link } from "react-router-dom";
import FundraiserVideo from "../assets/FundraiserVideo.mp4";

function Home() {
  return (
    <div className="container">
      <div className="top">
        {/* <img src={picture} className="picture" /> */}
        <div className="video-container">
          <iframe
            width="420"
            height="236"
            src={FundraiserVideo}
            frameBorder="0"
            allowFullScreen
          ></iframe>

          <audio controls src={warAudio}></audio>
        </div>
      </div>

      <div className="wholeBottom">
        <image src="../assets/Flag.jpg" />
        <div className="bottom">
          <h3 className="header">Saving lives in Israel</h3>
          {/* <h4 className="donationAmount">$600,232 funded of $700,000</h4> */}
        </div>

        <div className="overflowDiv"> 
          {/* <div className="line"></div> */}
          <div className="containerFacts">
            <h1 className="title">Security Threats</h1>
            <p className="content">
              Israeli citizens have had to live under constant security threats
              due to the conflict. Missile attacks and acts of terrorism have
              created an uncertain daily life.
            </p>

            <h2 className="subtitle">Military Service</h2>
            <p className="content">
              Many Israeli youths are obligated to complete military service as
              part of the country's defense forces. This impacts their education
              and career choices.
            </p>

            <h2 className="subtitle">Mental Health</h2>
            <p className="content">
              The conflict has led to an increase in mental health issues among
              Israeli citizens, especially those living near conflict zones.
            </p>

            <h2 className="subtitle">Political Polarization</h2>
            <p className="content">
              The conflict has generated political polarization in Israel,
              affecting the political climate and societal cohesion.
            </p>
          </div>
          {/* <div className="line"></div> */}
        </div>
        <div className="button-container">
          <Link to="/support" className="button">
            <h1>Support</h1>
          </Link>
          <h6 className="godkendelse">Verification in progress</h6>
        </div>

        <div className="collecting">
          <h5>
            This campaign will collect all funds raised by December 31, 2023 23:59
            AM.
          </h5>
        </div>
      </div>
    </div>
  );
}

export default Home;
